import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "電子処方箋実証事業における FHIR の活用と標準化の展望",
  "date": "2019-04-02T03:00:00.000Z",
  "slug": "entry/2019/04/02/02",
  "tags": ["medley"],
  "hero": "./2019_04_02.png",
  "heroAlt": "FHIR"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。CLINICS 事業部の児玉です。`}</p>
    <p>{`今回は、メドレーが 2018 年 12 月に厚生労働省から受託した「電子処方箋の本格運用に向けた実証事業」で、医療情報標準規格の`}<a parentName="p" {...{
        "href": "https://www.hl7.org/fhir/"
      }}>{`FHIR`}</a>{`を基盤とした電子処方箋管理システムを構築しましたので、その内容についてご紹介します。`}</p>
    <h1>{`FHIR とは`}</h1>
    <p>{`FHIR(Fast Healthcare Interoperability Resources)とは、医療情報交換の国際標準規格である HL7(Health Level 7)の中で最も新しい規格であり、インターネットテクノロジーをベースとした、シンプルで効率的にシステム間での情報共有を可能にする「`}<strong parentName="p">{`次世代の医療情報標準規格`}</strong>{`」として世界各国で注目されています。`}</p>
    <p>{`HL7 は、世界に 30 以上の国際支部を有しており、日本では 1998 年に`}<a parentName="p" {...{
        "href": "https://www.hl7.jp/"
      }}>{`日本 HL7 協会`}</a>{`が設立されました。そして、現在流通している HL7 規格には、データ交換に利用される`}<a parentName="p" {...{
        "href": "https://www.hl7.org/implement/standards/product_brief.cfm?product_id=185"
      }}>{`HL7v2`}</a>{`と、医用文書の記述に利用される`}<a parentName="p" {...{
        "href": "https://www.hl7.org/implement/standards/product_brief.cfm?product_id=7"
      }}>{`CDA`}</a>{`(Clinical Document Architecture)が存在します。`}</p>
    <h1>{`電子処方箋とは`}</h1>
    <p>{`電子処方箋とは、従来の紙に印刷された処方箋ではなく、医療機関と調剤薬局が電子データを用いて処方内容をやりとりする仕組みです。単純にペーパーレス化することだけが目的ではなく、患者個人の服薬履歴を電子的に管理して、重複投薬の適正化を図るといった目的もあります。`}</p>
    <h1>{`実証事業の背景と概略`}</h1>
    <p>{`2016 年 3 月の法令改正で、処方箋の電子的な交付が可能となり、`}<a parentName="p" {...{
        "href": "https://www.mhlw.go.jp/content/10800000/000342367.pdf"
      }}>{`運用ガイドライン`}</a>{`も策定されました。しかし、法令改正から数年が経過した現在においても、実運用での課題が払拭できずに、電子処方箋の全国的な普及は進んでおりません。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "ガイドラインに定められた運用フロー"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190328/20190328142501.png",
        "alt": "20190328142501.png"
      }}></img><figcaption parentName="figure">{`ガイドラインに定められた運用フロー（電子処方せんの運用ガイドラインより転載）`}</figcaption></figure>
    <p>{`元来、処方箋には医師の記名押印、または署名が必要であると医師法で定められています。現行のガイドラインは、この規則を踏襲して設計されていますので、紙の処方箋の代替として`}<strong parentName="p">{`CDA で記述された静的ファイル`}</strong>{`を必要とし、記名押印の代替として`}<a parentName="p" {...{
        "href": "https://www.jmaca.med.or.jp/hpki/"
      }}>{`HPKI`}</a>{`を使用した`}<strong parentName="p">{`電子署名`}</strong>{`が必要となります。`}</p>
    <p>{`また、クライアント端末で生成された静的ファイルが、インターネットに流通するフローとなっていますので、改ざんの検知を可能とするために`}<strong parentName="p">{`電子タイムスタンプ`}</strong>{`を付与する必要があります。`}</p>
    <p>{`このように複雑化された運用フローが、電子処方箋の普及を阻害する要因の 1 つであると考えられます。`}</p>
    <p>{`こうした状況を踏まえ、現行のガイドラインに縛られず、円滑な運用ができる仕組みを検討するために、実際の医療機関と調剤薬局を使用したフィールド実験を実施しました。`}</p>
    <hr></hr>
    <p><em parentName="p">{`実証事業概略`}</em></p>
    <ul>
      <li parentName="ul">{`実施目的：電子処方箋の運用の仕組みの検討・実証・考察`}</li>
      <li parentName="ul">{`実施期間：2019 年 2 月 4 日 - 2019 年 3 月 17 日`}</li>
      <li parentName="ul">{`実証エリア：東京都港区`}</li>
      <li parentName="ul">{`協力施設：2 医療機関 / 6 薬局`}</li>
      <li parentName="ul">{`利用実績：64 件`}</li>
    </ul>
    <hr></hr>
    <h1>{`電子処方箋管理システムの概要`}</h1>
    <p>{`以下に示すのは、今回の実証事業で開発した評価システムです。本システムは「処方箋管理システム」「医療機関システム」「薬局システム」「PHR アプリ」の 4 つのシステムから構成されています。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "システム概観"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190328/20190328140004.png",
        "alt": "20190328140004.png"
      }}></img><figcaption parentName="figure">{`システム概観`}</figcaption></figure>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`処方箋管理システム`}</strong></p>
        <p parentName="li">{`医療機関システム、薬局システム及び PHR アプリから接続され、各システムからの処理要求を受けて、処方データと調剤データの返答、作成、編集、及び削除を行う。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`医療機関システム`}</strong></p>
        <p parentName="li">{`診療結果としての処方データを処方箋管理システムに登録し、その結果として返される処方箋アクセスコードを患者に共有する。処方箋アクセスコードは QR コードの活用を想定し、QR コードを印字した紙又は電子データを患者に共有する。`}</p>
      </li>
    </ul>
    <div style={{
      "textAlign": "right",
      "fontSize": "7pt"
    }}>
＊「QR コード」は株式会社デンソーウェーブの登録商標です。
    </div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`薬局システム`}</strong></p>
        <p parentName="li">{`訪問してきた患者の本人確認を行った上で、処方箋アクセスコードを受け取り、処方箋管理システムにアクセスし処方データを参照する。処方後は調剤データを処方箋管理システムに格納する。`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PHR アプリ`}</strong></p>
        <p parentName="li">{`患者がオンライン診療やお薬手帳の機能を利用するためのアプリ。処方箋管理システムへのアクセスが許可され、医療機関システムから受け取った処方箋アクセスコードを元に、調剤結果を参照することを可能にする。`}</p>
      </li>
    </ul>
    <hr></hr>
    <p>{`医療機関システムは、メドレーのクラウド電子カルテ「CLINICS カルテ」を利用しましたが、システム連携に関わる部分については、他の電子カルテなどでも活用できるよう、疎に結合した設計を意識しました。`}</p>
    <p>{`処方箋管理システムは、前述の通り FHIR インターフェイスを基盤として構築しました。これは現行のガイドラインには記載されていない新たな試みです。`}</p>
    <p>{`HPKI を利用した`}<strong parentName="p">{`SSO(Single Sign On)による本人資格確認`}</strong>{`と、FHIR インターフェイスを利用した`}<strong parentName="p">{`クラウドベースでのデータフロー`}</strong>{`を実現することが可能であれば、複雑化の要因となっている静的ファイル、電子署名、タイムスタンプは不要であると考えました。`}</p>
    <h1>{`データ設計`}</h1>
    <p>{`FHIR には「リソース」と呼ばれるデータセットが定められています。リソースは、Patient(患者)、Practitioner(施術者)、Organization(組織)といった粒度で設計されており、リソース単位でのデータ交換が可能です。`}</p>
    <p>{`FHIR を利用するためには、その目的に応じたリソースの選定と、リソースの下位概念であるフィールドに設定する具体的な値を定義する必要があります。`}</p>
    <p>{`例えば、日本では人名を記述する際に、漢字氏名とカナ氏名を併記することが一般的ですが、グローバル・スタンダードではありません。このような国の事情に応じて、ローカライズされた記述ルールを定める必要があります。`}</p>
    <p><em parentName="p">{`実証事業で使用したリソース`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`リソース`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`説明`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/patient.html"
            }}>{`Patient`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`患者`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/practitioner.html"
            }}>{`Practitioner`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`施術者（処方医／薬剤師）`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/practitionerrole.html"
            }}>{`PractitionerRole`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`施術者役割`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/organization.html"
            }}>{`Organization`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`組織（医療機関／調剤薬局）`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/medicationrequest.html"
            }}>{`MedicationRequest`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`投薬要求`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/medicationdispense.html"
            }}>{`MedicationDispense`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`調剤実施`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.hl7.org/fhir/coverage.html"
            }}>{`Coverage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`保険`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`FHIR を使用した所感`}</h1>
    <p>{`FHIR の改版は on-Going で行われています。実証事業のための開発を始めた頃は、STU3(Standard for Trial Use 3)が公式バージョンでした。しかし、あるとき急に FHIR のホームページが接続不安定になり困っていたところ、翌日には R4(Release 4)に更新されていたということもありました。`}</p>
    <p>{`このように、日進月歩の FHIR をプロダクトに組み込むには、タイミングの見極めと、アップデートに追従する「覚悟」を決めることが大切だと思います。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "2018 年 12 月 27 日に R4 が Current Version になりました"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190328/20190328210514.png",
        "alt": "20190328210514.png"
      }}></img><figcaption parentName="figure">{`2018 年 12 月 27 日に R4 が Current Version になりました`}</figcaption></figure>
    <p>{`FHIR の利点は、従来の HL7 規格と比較した`}<strong parentName="p">{`実装容易性`}</strong>{`にあると考えます。REST(REpresentational State Transfer)のように、Web サービスでは一般的に普及している概念を標準として取り込んでいるため、`}<strong parentName="p">{`実装者は特別な知識を習得する必要がありません。`}</strong>{`また、FHIR を実装するためのオープンソースライブラリが豊富に提供されており、これらを活用することによって、本当に必要な処理に注力して開発することが可能となります。`}</p>
    <p>{`臨床概念モデルとしての観点では、CDA のベースとなっている`}<a parentName="p" {...{
        "href": "https://www.hl7.org/implement/standards/rim.cfm"
      }}>{`HL7v3-RIM`}</a>{`(Reference Information Model)と比較すると、クラス、継承のようなオブジェクト指向の概念が廃止されています。あらゆる臨床概念をモデリング可能とすることを目指した RIM と比べると、さすがに表現可能な範囲は狭まると思われますが、網羅性は実用域のレベルには達しているのではないでしょうか。`}</p>
    <p>{`また、1:1 のシステム連携におけるデータ交換では、すでに稼働している v2 インターフェイスを、無理して FHIR に置き換える必要は無いと思います。用途に応じて、既存の規格と共存していくのが大切であると考えます。`}</p>
    <h1>{`実運用へ向けての課題`}</h1>
    <p>{`FHIR リソースは、臨床概念としてユニバーサルに利用可能なものを中心に構成されています。保険情報のような各国の医療制度に応じて異なるものや、調剤技法に対する「一包化」や「粉砕」などの細かな指示情報に関しては、ある程度アドホックにマッピングするしかありませんでした。実装者によるマッピングのブレが生じないようにするためには、`}<strong parentName="p">{`指標となるガイドラインの策定`}</strong>{`が必要になります。`}</p>
    <p>{`また、リソースにマッピングする医薬品や用法のマスターコードについては、`}<a parentName="p" {...{
        "href": "https://helics.umin.ac.jp/helicsStdList.html"
      }}>{`厚生労働省標準マスター`}</a>{`の利用が望まれます。しかしながら、満遍なく普及しているとは言い難い状況ですので、`}<strong parentName="p">{`標準マスター利用の推奨`}</strong>{`も併せてガイドラインに明記することが必要です。`}</p>
    <p>{`ガイドラインは、メドレー 1 社で策定することが可能なものではなく、医療情報システムの開発に関わる多くの開発者に FHIR を利用していただき、`}<strong parentName="p">{`実装方法についての議論`}</strong>{`を交わす必要があります。しかし、現時点での日本国内における FHIR の活用事例は、残念ながらほとんど見られません。`}</p>
    <p>{`次にご紹介するのは、FHIR を使用した簡単なコードのサンプルと、ローカル環境で FHIR Server を簡易的に動かす方法です。実装者の方が FHIR に触れるきっかけになればと思って書きましたので、ご参考にしていただけますと幸いです。`}</p>
    <h1>{`FHIR の実装サンプル`}</h1>
    <p>{`FHIR を理解するためには、実際にコードを書いて動かしてみるのが一番だと思います。ここでは、ローカル環境で FHIR Server を起動して、リソースを登録する簡単なプログラムを書いてみました。（オープンソースの恩恵を最大限に享受しています。）`}</p>
    <h2>{`実行環境`}</h2>
    <ul>
      <li parentName="ul">{`macOS v10.14.3`}</li>
      <li parentName="ul">{`Ruby v2.5.1`}</li>
      <li parentName="ul">{`Docker v18.09.2`}</li>
    </ul>
    <h2>{`FHIR Server`}</h2>
    <p>{`Docker コンテナで FHIR Server を起動します。簡単に動かせるように、DockerHub に公開されている`}<a parentName="p" {...{
        "href": "https://hapifhir.io/"
      }}>{`HAPI-FHIR`}</a>{`のコンテナイメージを利用します。`}</p>
    <p>{`HAPI-FHIR とは、カナダのトロントにある医療研究機関の UHN(University Health Network)が立ち上げたプロジェクトで、FHIR のオープンソースライブラリを提供することを目的に活動しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/jamesagnew/hapi-fhir"
      }}>{`https://github.com/jamesagnew/hapi-fhir`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://hub.docker.com/r/petersonjared/hapi-fhir"
      }}>{`https://hub.docker.com/r/petersonjared/hapi-fhir`}</a></p>
    <p>{`Docker コンテナイメージの取得`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` pull petersonjared/hapi-fhir
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -d -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:8080 --name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`fhir petersonjared/hapi-fhir:dstu3
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it fhir /docker-entrypoint.sh java -jar /usr/local/jetty/start.jar`}</code></pre></div>
    <p>{`Docker をインストールするのが面倒な人は、HAPI-FHIR がグローバルに公開している`}<a parentName="p" {...{
        "href": "https://hapi.fhir.org/baseDstu3"
      }}>{`テストサーバー`}</a>{`を利用することも可能です。ただし、世界中の人がテストに利用しているサーバーですので、機微な個人情報は登録しないよう注意が必要です。`}</p>
    <h2>{`FHIR Client`}</h2>
    <p>{`FHIR Client は、Ruby で動かします。以下の Gem を利用します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ gem `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` fhir_client
$ gem `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` fhir_models`}</code></pre></div>
    <p>{`Ruby のコードです。Patient リソースを作成して FHIR Server に登録します。`}</p>
    <p><em parentName="p">{`patient.rb`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'fhir_client'`}</span></span>{`

client `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FHIR`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Client`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"https://localhost:8080/baseDstu3"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FHIR`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span>{`Model`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`client `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` client

patient `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FHIR`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Patient`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 患者 ID`}</span>{`
identifier `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FHIR`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Identifier`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
identifier`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'12345678'`}</span></span>{`
patient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`identifier `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` identifier

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 患者氏名`}</span>{`
human_name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`FHIR`}</span><span parentName="code" {...{
            "className": "token double-colon punctuation"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`HumanName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{`
human_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`family `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'Kodama'`}</span></span>{`
human_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`given `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'Yoshinori'`}</span></span>{`
patient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`push`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`human_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 性別`}</span>{`
patient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`gender `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'male'`}</span></span>{`

patient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create
puts patient`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}</code></pre></div>
    <p>{`上記のコードを実行すると、FHIR Server に Patient リソースが登録されます。ここでリソースを一意に特定するリソース ID が採番されます。今回は `}<strong parentName="p">{`19953`}</strong>{` が採番されました。（puts patient.id で確認）`}</p>
    <p>{`このリソース ID を利用して、FHIR Server に HTTP リクエストを送ります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` https://localhost:8080/baseDstu3/Patient/19953`}</code></pre></div>
    <p>{`そうすると、先ほど登録したリソースが JSON 形式で取得できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"resourceType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Patient"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"19953"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"meta"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"versionId"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"lastUpdated"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2019-03-21T09:12:44.660+00:00"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"text"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"status"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"generated"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"div"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"<div xmlns=\\"https://www.w3.org/1999/xhtml\\"><div class=\\"hapiHeaderText\\">Yoshinori <b>KODAMA </b></div><table class=\\"hapiPropertyTable\\"><tbody><tr><td>Identifier</td><td>12345678</td></tr></tbody></table></div>"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"identifier"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"12345678"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"family"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Kodama"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"given"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Yoshinori"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"gender"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"male"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`もちろん、患者 ID など利用して検索することも可能です。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` https://localhost:8080/baseDstu3/Patient?identifier`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12345678`}</span></code></pre></div>
    <h1>{`さいごに`}</h1>
    <p>{`今回の実証事業では、FHIR のインターフェイスを活用することにより、シンプルなアーキテクチャとシームレスなフローで、迅速に処方箋管理システムの構築ができ、電子処方箋の運用評価を行うことができました。本実証事業の最終成果報告書は`}<a parentName="p" {...{
        "href": "https://www.mhlw.go.jp/stf/denshishohousen.html"
      }}>{`厚生労働省のサイト`}</a>{`に公開されていますので、詳細について興味のある方はこちらをご参照ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.mhlw.go.jp/stf/denshishohousen.html"
      }}>{`https://www.mhlw.go.jp/stf/denshishohousen.html`}</a></p>
    <p>{`メドレーは、実証事業で得られた知見を可能な限りオープンにして電子処方箋の普及推進に取り組むことはもちろん、インターネットを活用したオープンな技術の普及活動に積極的に取り組み、医療機関と患者の双方にとってより良い医療の実現を目指してまいります。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`今回の電子処方箋実証事業の成果と、システム開発に活用した FHIR について共有するイベントを、以下のとおり 4 月 23 日(火)に開催します。お時間ある方はぜひご参加ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://techplay.jp/event/725549"
      }}>{`https://techplay.jp/event/725549`}</a></p>
    <p>{`また、メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいメンバーを、デザイナー・エンジニアを中心に全職種絶賛募集中です。皆さまからのご応募お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      